<template>
  <div>
    <div>
      <v-row>
        <v-col>
          <v-switch
            v-model="testDay"
            label="Available Today"
            @change="getToday()"
          ></v-switch>
        </v-col>
      </v-row>
    </div>
    <!-- <br /> -->
    <div>
      <gmap-map
        :zoom="15"
        :center="center"
        :options="options"
        :style="{ width: '100%', height: `${windowHeight - 160}px` }"
        :mapTypeControlOptions="mapControlOption"
      >
        <view-store-dialog
          v-if="show"
          :visible="show"
          :store="selectedStore"
          @CloseDialog="CloseDialog()"
        />
        <gmap-marker
          @mouseover="test()"
          @mouseout="testBye"
          :key="index"
          v-for="(m, index) in locationMarkers"
          :position="m.position"
          :title="m.title"
          :label="m.label"
          :icon="m.icon"
          @click="showStoreDetails(m)"
        ></gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import ViewStoreDialog from "./ViewStoreDialog.vue";
import service from "../services/service";
import AdminService from "../services/AdminService";
export default {
  name: "AddGoogleMap",
  components: {
    ViewStoreDialog,
  },
  data() {
    return {
      mapControlOption: {
        mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
      },
      windowHeight: window.innerHeight,
      testDay: true,
      options: {
        disableDefaultUI: false,
        scrollwheel: true,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },

          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          }, //turns off highway labels
          {
            featureType: "road.arterial",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.local",
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          }, //turns off local roads labels
        ],
      },
      center: {
        lat: 40.724663768,
        lng: -73.990329372,
      },
      locationMarkers: [],
      NearByPoints: [
        { position: { lat: 40.780599, lng: -73.962353 } },
        { position: { lat: 40.789583, lng: -73.962353 } },
        { position: { lat: 40.780599, lng: -73.974217 } },
        { position: { lat: 40.789583, lng: -73.974217 } },
      ],
      locPlaces: [],
      existingPlace: null,
      selectedStore: {},
      show: false,
    };
  },
  mounted() {
    console.log(this.windowHeight);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center.lat = position.coords.latitude;
          this.center.lng = position.coords.longitude;
          // this.getStores();
          this.getAllLogs();
        },
        (error) => {
          console.log(error.message);
          // this.getStores();
          this.getAllLogs();
        }
      );
    } else {
      // this.getStores();
      this.getAllLogs();
    }
    this.showUserLocationOnMap;
  },

  methods: {
    async getStores() {
      const icon =
        "https://spring-boot-repo-tpsi.s3.amazonaws.com/2875E5F7-049F-494D-BDDC-9F41DB998BC8.png";
      const response = await service.getCheckedStoresWithAllData();
      const today = this.getToday();
      //We are going through each store, to add geolocation and if there is hh,
      //we add high discounted percentage of the day if there is any
      //Structure: storeInfo(response.data[i]) -> hhResult[array] -> info (Entry) -> days(contains day information) -> items -> prices
      for (let i = 0; i < response.data.length; i++) {
        let discounted_pct = 100;
        if (response.data[i].hhResult.length > 0) {
          // if there is happy hour information
          let entries = response.data[i].hhResult[0].infos; //this is entries
          for (let j = 0; j < entries.length; j++) {
            // console.log(entries[j]);
            // console.log(response.data[i].name);
            // go through the days in each entry
            let days = entries[j].days; // array of days
            for (let k = 0; k < days.length; k++) {
              // console.log(response.data[i].name);
              // go through days to see if today is there
              // console.log(days[k]);
              // if (calculateEntry) continue;
              // console.log(days[k]);
              if (days[k] == today) {
                // console.log(entries[j]);
                // console.log(entries[j]);
                if (entries[j].items && entries[j].items.length > 0) {
                  for (let a = 0; a < entries[j].items.length; a++) {
                    let item = entries[j].items[a];
                    let local_discount = 100;
                    if (item.discounted_price != 0 && item.regular_price != 0) {
                      local_discount = item.discounted_price / item.regular_price;
                      // console.log(
                      //   local_discount + `  ${response.data[i].name}`
                      // );
                    }
                    if (discounted_pct > local_discount) {
                      discounted_pct = local_discount;
                    }
                  }
                  discounted_pct = 100 - discounted_pct * 100;
                  discounted_pct = discounted_pct.toFixed(0);
                  if (discounted_pct < 0) {
                    discounted_pct = 100;
                  }
                }
              }
            }
          }
        }

        const marker = {
          lat: Number(response.data[i].latitude),
          lng: Number(response.data[i].longitude),
        };
        if (discounted_pct == 100) {
          this.locationMarkers.push({
            anchor: marker,
            position: marker,
            details: response.data[i],
            title: response.data[i].name,
            label: "",
            icon: {
              // url: test,
              scaledSize: {
                width: 80,
                height: 80,
              },
              labelOrigin: { x: 16, y: -10 },
            },
          });
        } else {
          this.locationMarkers.push({
            position: marker,
            details: response.data[i],
            title: response.data[i].name,
            label: {
              text: discounted_pct.toString() + "%",
              color: "black",
            },
            icon: {
              anchor: new google.maps.Point(66, 41),
              url: icon,
              scaledSize: {
                width: 140,
                height: 110,
              },
              labelOrigin: { x: 72, y: 24 },
            },
          });
        }
      }
    },
    async getAllLogs() {
      const icon =
        "https://spring-boot-repo-tpsi.s3.amazonaws.com/2875E5F7-049F-494D-BDDC-9F41DB998BC8.png";
      const response = await AdminService.getAllLogs();
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        let marker = {
          lat: Number(response.data[i].latitude),
          lng: Number(response.data[i].longitude),
        };
        this.locationMarkers.push({
          anchor: marker,
          position: marker,
          details: response.data[i],
          title: response.data[i].name,
          label: "",
          icon: {
            // url: test,
            scaledSize: {
              width: 80,
              height: 80,
            },
            labelOrigin: { x: 16, y: -10 },
          },
        });
      }
    },
    getToday() {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var today = "";
      days.forEach((day, index) => {
        // Check if the index of day value is equal to the returned value of getDay()
        if (index == new Date().getDay()) {
          today = day;
        }
      });
      return today;
      //check each array, if there is not data for today, remove it from the list
      // for (let i = 0; i < this.locationMarkers.length; i++) {
      //   console.log(this.locationMarkers[i]);
      // }
    },
    test() {
      // console.log("hi");
    },
    testBye() {
      // console.log("bye");
    },
    CloseDialog() {
      this.show = false;
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    showStoreDetails(item) {
      //Set center
      //@click="center = m.position"
      this.selectedStore = item.details;
      this.show = true;
      console.log(item);
    },
    // addLocationMarker() {
    //   if (this.existingPlace) {
    //     const marker = {
    //       lat: this.existingPlace.geometry.location.lat(),
    //       lng: this.existingPlace.geometry.location.lng(),
    //     };
    //     this.locationMarkers.push({ position: marker });
    //     this.locPlaces.push(this.existingPlace);
    //     this.center = marker;
    //     this.existingPlace = null;
    //   }
    // },

    showUserLocationOnMap: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        console.log(res);
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
  },
};
</script>

<style>
.googleMap {
  width: 100%;
  height: 800px;
}
</style>
