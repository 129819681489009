<template>
  <div>
    <!-- <view-store-dialog
      v-if="show"
      :visible="show"
      :store="selectedStore"
      @CloseDialog="CloseSuccessDialog()"
    /> -->

    <v-data-table
      :headers="headers"
      :items="stores"
      :sort-by="sort"
      item-key="_id"
      class="elevation-1 table"
      :search="search"
      :items-per-page="30"
      :page="page"
      @update:page="pageChange"
      @update:sort-by="sortChange"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Store List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="blue" class="white--text ml-2" @click="$router.push('/newstore')"
            >New Store</v-btn
          >
        </v-toolbar>
        <v-toolbar>
          <NavBtn />
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mr-2" color="info" @click.stop="goToEdit(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import service from "@/services/service";
import ViewStoreDialog from "@/components/ViewStoreDialog.vue";
import NavBtn from "@/components/NavButtons.vue";
export default {
  components: {
    ViewStoreDialog,
    NavBtn,
  },
  data: () => ({
    sort: "name",
    page: 1,
    currentPage: 1,
    cuisineList: [],
    filterDays: [],
    filterItems: [],
    filterCuisine: "",
    filterDaysList: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    filterItemsList: ["Wine", "Food", "Beer", "Cocktail"],
    showDialog: false,
    show: false,
    selectedStore: {},
    search: "",
    expanded: [],
    singleExpand: true,
    stores: [],
    storesBackup: [],
    dialogs: {
      viewStoreDialog: false,
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Location", value: "location" },
      { text: "Phone", value: "number" },
      { text: "Cuisine", value: "cuisine" },
      { text: "Checked", value: "checked" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  async mounted() {},

  async created() {
    const response = await service.indexAllData();
    this.stores = response.data;
    this.storesBackup = this.stores;
    //check if there is sessionStorage
    const filterResult = JSON.parse(sessionStorage.getItem("filterResult"));
    if (filterResult) {
      //if there is result
      this.stores = filterResult;
      this.filterCuisine = sessionStorage.getItem("filterCuisine");
      this.filterDays = JSON.parse(sessionStorage.getItem("filterDays"));
    }
    this.cuisineList = await this.getAllCuisineForFilter();
    this.goToRightPage();
    this.sort = sessionStorage.getItem("sort");
  },

  methods: {
    goToRightPage() {
      const page = sessionStorage.getItem("currentPage");
      this.page = parseInt(page);
      console.log(typeof this.page);
      console.log(this.page);
    },
    pageChange(newPage) {
      console.log(newPage);
      this.currentPage = newPage;
    },
    sortChange(newSort) {
      console.log(newSort);
      sessionStorage.setItem("sort", newSort);
    },
    //get cuisine list for search bar
    async getAllCuisineForFilter() {
      const response = await service.getAllCuisines();
      // console.log(response.data);
      var cuisineData = [];
      for (let i = 0; i < response.data.length; i++) {
        cuisineData[i] = response.data[i].name;
      }
      return cuisineData;
    },
    //for cleaning the filter
    clearFilter() {
      this.filterCuisine = "";
      this.filterDays = [];
      sessionStorage.clear();
      this.page = 1;
      // this.sort = "name";
      this.stores = this.storesBackup;
    },
    //function for running search
    runFilter() {
      this.stores = this.storesBackup;
      var storeNew = [];
      this.page = 1;
      this.sort = "name";
      if (this.filterCuisine) {
        // for (let i = 0; i < this.stores.length; i++) {
        //   if (this.stores[i].cuisine) {
        //     const cuisine = this.stores[i].cuisine[0];
        //     if (cuisine === this.filterCuisine) {
        //       storeNew.push(this.stores[i]);
        //     }
        //   }
        // }
        storeNew = this.runCuisineFilter(this.stores);
      }
      if (this.filterDays.length > 0) {
        storeNew = this.runDayFilter(storeNew);
      }
      console.log(storeNew);
      //check if there is filter for items
      if (this.filterItems.length > 0) {
      }
      this.stores = storeNew;
      // console.log(this.stores);
      const filterResult = JSON.stringify(this.stores);
      sessionStorage.setItem("filterResult", filterResult);

      // this.stores = [];
    },
    runCuisineFilter(allStores) {
      sessionStorage.setItem("filterCuisine", this.filterCuisine);
      var storeList = [];
      for (let i = 0; i < allStores.length; i++) {
        if (allStores[i].cuisine) {
          const cuisine = allStores[i].cuisine[0];
          if (cuisine === this.filterCuisine) {
            storeList.push(allStores[i]);
          }
        }
      }
      return storeList;
    },
    runDayFilter(storeList) {
      sessionStorage.setItem("filterDays", JSON.stringify(this.filterDays));
      storeList = storeList.filter((store) => store.hhResult.length > 0);
      //after filter, all the stores are having hhResult
      for (let i = 0; i < storeList.length; i++) {
        const currentStoreHHDays = storeList[i].hhResult[0].infos[0].days;
        // console.log(currentStoreHHDays);
        console.log(this.compareArrays(storeList, this.filterDays));
      }

      return storeList;
    },
    compareArrays(storeDays, filterDays) {
      return filterDays.every((el) => {
        return storeDays.includes(el);
      });
    },
    createNewStore() {
      console.log("take user to new store page.");
      this.navigateTo({ name: "NewStoreView" });
    },
    goToEdit(item) {
      sessionStorage.setItem("currentPage", this.currentPage);
      this.navigateTo({
        name: "EditStoreView",
        params: {
          storeId: item._id,
        },
      });
    },
    navigateTo(route) {
      window.open(this.$router.resolve(route).href);
    },
    showStoreDetails(value) {
      this.selectedStore = value;
      this.show = true;
    },
    async deleteItem(item) {
      const response = await service.deleteStore(item._id);
      if (response.data) {
        this.$router.go(0);
      }
    },
  },
};
</script>

<style></style>
