<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center justify-center py-6">
        <v-row>
          <v-col cols="8">
            <h1 class="pr-12 font-weight-bold text-h2">Happy Hour</h1>
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="happyHourContent.bar_patio_only"
              label="Bar/Patio ONLY"
            ></v-switch>
          </v-col>
          <v-col cols="2">
            <v-row>
              <v-btn class="mb-1" color="info" @click="addEntry()">
                Add Entry
              </v-btn>
            </v-row>
            <v-row>
              <v-btn
                class="mt-1"
                color="error"
                :disabled="!happyHourContent.infos.length"
                @click="removeEntry()"
              >
                Remove Entry
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs v-model="tab" color="info" dark>
        <v-tab v-for="n in happyHourContent.infos.length" :key="n">
          Entry {{ n }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-alert color="red" dark :value="showAlert" class="overflow-y-auto">
          {{ warningContent }}
        </v-alert>
        <v-tab-item
          v-for="(info, index) in happyHourContent.infos"
          :key="index"
        >
          <v-card-text class="text-center"> </v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="info.days"
                  :items="days"
                  label="Select"
                  multiple
                  outlined
                  chips
                  hint="Select Days in week"
                  prepend-icon="mdi-calendar"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="info.start_time"
                  prepend-icon="mdi-clock-time-four-outline"
                  label="Start Time 24H Format"
                ></v-text-field
              ></v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="info.end_time"
                  prepend-icon="mdi-clock-time-four-outline"
                  label="End Time 24H Format"
                ></v-text-field
              ></v-col>
              <v-col class="mt-3" cols="2">
                <v-btn block @click="copy(index)">COPY CONTENT</v-btn>
              </v-col>
              <v-col class="mt-3" cols="2">
                <v-btn block @click="paste(index)">PASTE TO TODAY</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in info.items" :key="index">
              <v-col cols="2">
                <v-text-field
                  v-model="item.name"
                  label="Item Name"
                ></v-text-field
              ></v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="item.discounted_price"
                  label="Discounted Price"
                ></v-text-field
              ></v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="item.regular_price"
                  label="Regular Price"
                ></v-text-field
              ></v-col>
              <v-col cols="2">
                <v-select
                  v-model="item.type"
                  label="Type"
                  :items="itemTypes"
                ></v-select
              ></v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="item.alcohol"
                  label="Alcohol %"
                ></v-text-field
              ></v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="item.taste_score"
                  label="Taste Score"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="item.innovation_score"
                  label="Innovation Score"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="item.ingredients"
                  label="Ingredients"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="item.portion_score"
                  label="Portion Score"
                ></v-text-field
              ></v-col>
              <v-col cols="2"
                ><v-btn color="error" @click="removeItem(index, info)"
                  >Remove</v-btn
                ></v-col
              >
            </v-row>

            <v-btn block color="info" @click="addItem(info)"
              >ADD ITEM FOR THIS ENTRY</v-btn
            >
          </v-container>
          <v-container>
            <v-btn block color="success" @click="saveHappyHour()">
              SAVE HAPPY HOUR
            </v-btn>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <result-dialog
      v-if="showResultDialog"
      :visible="showResultDialog"
      :result="updateResult"
      :status="updateStatus"
    />
  </v-container>
</template>
<script>
import service from "@/services/service";
import ResultDialog from "./ResultDialog.vue";
export default {
  components: {
    ResultDialog,
  },
  props: {
    store_id: String,
  },
  async mounted() {
    this.happyHourContent.store_id = this.store_id;
    const response = await service.getStoreHappyHour(this.store_id);
    if (response.data) {
      console.log(response.data);
      this.happyHourContent = response.data;
    }
  },
  watch: {
    length(val) {
      this.tab = val - 1;
    },
  },
  data() {
    return {
      itemTypes: ["Food", "Wine", "Beer", "Cocktail"],
      updateStatus: "",
      showResultDialog: false,
      updateResult: "",
      showAlert: false,
      warningContent: "",
      tab: null,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      currentCopyContent: {},
      happyHourContent: {
        infos: [],
      },
    };
  },
  methods: {
    copy(index) {
      console.log(index);
      console.log(this.happyHourContent.infos[index]);
      this.currentCopyContent = this.happyHourContent.infos[index];
    },

    paste(index) {
      if (this.currentCopyContent.days) {
        console.log(this.currentCopyContent);
        console.log(this.happyHourContent.infos);
        this.happyHourContent.infos[index].days = this.currentCopyContent.days;
        this.happyHourContent.infos[index].start_time =
          this.currentCopyContent.start_time;
        this.happyHourContent.infos[index].end_time =
          this.currentCopyContent.end_time;
        this.happyHourContent.infos[index].items =
          this.currentCopyContent.items;
      } else {
        this.warningContent = "Didn't copy any content...";
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
          this.warningContent = "";
        }, 3000);
      }
      this.currentCopyContent = {};
    },
    async saveHappyHour() {
      console.log(this.happyHourContent);
      const response = await service.saveStoreHappyHour(this.happyHourContent);
      console.log(response.data);
      this.updateStatus = response.data;
      if (response.data) {
        this.showResultDialog = true;
        this.updateResult = "Update Success...";
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
          this.$router.go(0);
        }, 2000);
      } else {
        this.showResultDialog = true;
        this.updateResult = "Update Is Not Success....";
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
        }, 2000);
      }
    },
    //extend one more infos length
    addEntry() {
      const lastEntry = this.happyHourContent.infos.length;
      if (
        lastEntry < 1 ||
        this.happyHourContent.infos[lastEntry - 1].days != ""
      ) {
        this.happyHourContent.infos.push({
          days: [],
          start_time: "",
          end_time: "",
          items: [],
        });
      } else {
        this.showAlert = true;
        this.warningContent = "Please fill out last entry...";
        setTimeout(() => {
          this.showAlert = false;
          this.warningContent = "";
        }, 2500);
      }
    },

    removeEntry() {
      // console.log(this.tab);
      this.happyHourContent.infos.splice(this.tab, 1);
    },

    removeItem(index, info) {
      console.log(index);
      console.log(info.items);
      info.items.splice(index, 1);
    },
    addItem(info) {
      console.log(info);
      //we need to check if the last item is empty
      const lastItemIndex = info.items.length;
      console.log(lastItemIndex);
      if (lastItemIndex < 1 || info.items[lastItemIndex - 1].name != "") {
        info.items.push({
          name: "",
          discounted_price: "",
          regular_price: "",
          type: "",
        });
      } else {
        this.showAlert = true;
        this.warningContent = "Please fill out last item first...";
        setTimeout(() => {
          this.showAlert = false;
          this.warningContent = "";
        }, 2500);
      }
    },
  },
};
</script>
