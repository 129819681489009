<template>
  <v-container fluid>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">User</th>
            <th class="text-left">Action</th>
            <th class="text-left">Type</th>
            <th class="text-left">Description</th>
            <th class="text-left">Latitude</th>
            <th class="text-left">Longitude</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in data" :key="log._id">
            <td>{{ log.date ? log.date : log.date.slice(0, 19) }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.action }}</td>
            <td>{{ log.type }}</td>
            <td>{{ log.description }}</td>
            <td>{{ log.latitude }}</td>
            <td>{{ log.longitude }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  props: {
    data: Array,
  },
};
</script>

<style lang="scss" scoped></style>
