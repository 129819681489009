<template>
  <v-container>
    <div class="stage1">
    <v-row>
      <v-col align="center">
        <div class="text-h5">Tell Us Your Business Name</div>
      </v-col>
    </v-row>
    <div class="dropdown">
      <v-row>
        <v-col align="center">
          <v-text-field
            outlined
            dense
            label="Search For Business Name"
            placeholder="TPSI Restaurant"
            v-model="search"
            @keyup="userSearch(search)"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="options" v-if="this.search && showSearch">
        <v-row>
          <v-col>
            <ul>
              <li v-for="(store, i) in stores" :key="i" @click="selectStore(i)">
                <v-row
                  ><div class="name">{{ store.name }}</div></v-row
                >
                <v-row
                  ><div class="address">
                    {{ store.location }}
                  </div></v-row
                >
              </li>
            </ul>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
  </v-container>
</template>

<script>
export default {
  name: "inputStageOne",
  props: ["stores", "currentStore"],
  emits: ["searchStore", "userSelectStore"],
  data: () => ({
    // windowHeight: window.innerHeight,
    search: "",
    selectedStore: {},
    showSearch: true,
  }),
  mounted() {
    if (this.currentStore.name != null) {
      this.search = this.currentStore.name;
    }
  },
  methods: {
    selectStore(index) {
      console.log("selected Store is at " + index);
      this.selectedStore = this.stores[index];
      console.log(this.selectedStore);
      this.search = this.selectedStore.name;
      this.$emit("userSelectStore", this.selectedStore);
      this.showSearch = false;
    },
    userSearch(searchWord) {
      this.showSearch = true;
      console.log(searchWord);
      this.$emit("searchStore", searchWord);
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  margin-top: 1px;
}

ul {
  text-align: left;
  padding-left: 0px;
  overflow-y: scroll;
  max-height: 250px;
}
li {
  list-style: none;
  text-align: left;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 20px;
  //   margin-left: 5px;
  background-color: #f1f1f1;
  &:hover {
    background-color: #70878a;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
}

.name {
  font-size: larger;
}
.address {
  font-size: small;
  color: #575757;
}
</style>
