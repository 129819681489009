<template>
  <v-dialog max-width="800" v-model="show">
    <v-card>
      <v-card-title>
        <InputStageOne
          :currentStore="selectedStore"
          :stage="1"
          :stores="filteredStores"
          @searchStore="searchStore"
          @userSelectStore="storeSelection"
        />
      </v-card-title>
      <v-card-subtitle>
        <div>Location: {{ storeLocation }}</div>
        <div>
          <v-text-field
            label="Expiration Date"
            placeholder="YYYY-MM-DD"
            v-model="expirationDate"
          ></v-text-field>
        </div>
        <div>
          <v-text-field label="Note" v-model="note"></v-text-field>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div v-for="i in deals.length" :key="i">
          <v-row>
            <v-col cols="11"
              ><v-text-field label="Deal" clearable v-model="deals[i - 1]"></v-text-field
            ></v-col>
            <v-col cols="1"
              ><v-btn class="mt-2" color="error" rounded @click="removeDeal(i)"
                ><v-icon>mdi-delete</v-icon></v-btn
              ></v-col
            >
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-btn color="info" dark block @click="addNewDeal()">Add Deals</v-btn>
          </v-col>
          <v-col>
            <v-btn color="green" dark block @click="postCoupon()">SAVE</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from "@/services/service";
import InputStageOne from "@/components/InputStageOne.vue";
export default {
  props: {
    value: Boolean,
  },
  components: {
    InputStageOne,
  },
  data: () => ({
    allStores: {},
    // filteredStores: {},
    stage: 1,
    storeLocation: "",
    searchQuery: "",
    selectedStore: {},
    deals: [],
    expirationDate: "",
    coupon: {
      store: "",
      location: "",
      expirationDate: "",
      deals: "",
      note: "",
    },
    note: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    filteredStores() {
      const query = this.searchQuery.toLowerCase();
      if (query === "") {
        return {};
      }
      return this.allStores.filter(
        (store) => store.name != null && store.name.toLowerCase().includes(query)
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    addNewDeal() {
      if (this.deals[this.deals.length - 1] == "") {
        console.log("cannot add more");
        return;
      } else {
        this.deals.push("");
      }
    },
    removeDeal(index) {
      this.deals.splice(index - 1, 1);
    },
    async init() {
      const response = await service.indexAllData();
      this.allStores = response.data;
      console.log(this.allStores);
    },
    searchStore(search) {
      this.searchQuery = search;
    },
    storeSelection(store) {
      console.log("Main: User selected: " + store.toString());
      this.selectedStore = store;
      this.storeLocation = store.location;
    },
    async postCoupon() {
      console.log(this.selectedStore);
      this.coupon.store = this.selectedStore.name;
      this.coupon.store_id = this.selectedStore._id;
      this.coupon.location = this.storeLocation;
      this.coupon.expirationDate = this.expirationDate;
      this.coupon.deals = this.deals;
      this.coupon.note = this.note;
      console.log(this.coupon);
      const response = await service.postNewCoupon(this.coupon);
      console.log(response.data);
      if (response.data == true) {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
