<template>
  <div id="map">
    <v-container fluid>
      <add-google-map />
    </v-container>
  </div>
</template>

<script>
import AddGoogleMap from "../components/AddGoogleMap.vue";
export default {
  components: {
    AddGoogleMap,
  },
  data() {
    return {
      lat: "",
      lng: "",
    };
  },
  mounted() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       this.lat = position.coords.latitude;
    //       this.lng = position.coords.longitude;
    //       console.log(this.lat);
    //       console.log(this.lng);
    //     },
    //     (error) => {
    //       console.log(error.message);
    //     }
    //   );
    // }
  },
  methods: {
    showUserLocationOnMap(lat, lng) {
      let map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: new google.maps.LatLng(lat, lng),
        mapTypeId: google.maps.mapTypeId.ROADMAP,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
