<template>
  <div class="about">
    <landing-content />
    <v-app class="about">
      <v-container id="blog" fluid class="px-8">
        <v-row>
          <v-col v-for="n in 4" :key="n" sm="12" lg="4" md="6">
            <blog-card :index="n" />
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import LandingContent from "@/components/LandingContent.vue";
import BlogCard from "@/components/CouponCard.vue";
export default {
  components: {
    BlogCard,
    LandingContent,
  },
};
</script>

<style scoped>
.about {
  background-color: #faf4eb;
}
</style>
