import Api from "./api";

export default {
  getAllLogs() {
    return Api().get("/logs/");
  },
  // users
  getAllUsers() {
    return Api().get("/users/");
  },
  getAllUsage() {
    return Api().get("/logs/api-usage");
  }
  };
