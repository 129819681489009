<template>
  <v-container class="pb-12">
    <v-toolbar color="success" dark flat>
      <v-toolbar-title>Happy Hour</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab v-for="(item, index) in happyHour.infos" :key="index">
            Entry {{ index + 1 }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab" v-if="happyHour">
      <v-tab-item v-for="(item, index) in happyHour.infos" :key="index">
        <v-card flat>
          <!-- THIS IS FOR DESKTOP USERS -->
          <v-card-title class="ml-5 d-none d-sm-block">
            <v-row>
              <v-col cols="2" v-for="day in item.days" :key="day">{{
                day
              }}</v-col>
            </v-row>
          </v-card-title>
          <!-- THIS IS FOR IPHONE USERS -->
          <v-card-title class="ml-5 d-sm-none">
            <v-row>
              <v-col cols="5" v-for="day in item.days" :key="day">{{
                day
              }}</v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="ml-5">
            {{ item.start_time }} - {{ item.end_time }}</v-card-text
          >
          <v-container fluid>
            <!-- THIS IS FOR DESKTOP USERS -->
            <v-simple-table dense class="d-none d-sm-block">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">NAME</th>
                    <th class="text-left">DISCOUNTED PRICE</th>
                    <th class="text-left">REGULAR PRICE</th>
                    <th class="text-left">TYPE</th>
                    <th class="text-left">ALCOHOL%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in item.items"
                    :key="index"
                    class="mx-12"
                  >
                    <td>{{ product.name }}</td>
                    <td>${{ product.discounted_price }}</td>
                    <td>${{ product.regular_price }}</td>
                    <td>{{ product.type }}</td>
                    <td>{{ product.alcohol }}%</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- THIS IS FOR MOBILE USERS INCLUDING IPAD -->
            <v-simple-table dense class="d-sm-none">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">NAME</th>
                    <th class="text-left">OFF PRICE</th>
                    <th class="text-left">REG PRICE</th>
                    <th class="text-left">TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in item.items" :key="index">
                    <td>{{ product.name }}</td>
                    <td>${{ product.discounted_price }}</td>
                    <td>${{ product.regular_price }}</td>
                    <td>{{ product.type }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  props: {
    happyHour: Object,
  },
  data() {
    return {
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<style lang="scss" scoped></style>
