import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"width":"auto"}},[_c('image-view',{attrs:{"store_id":_vm.store._id}}),_c(VCardTitle,[_vm._v(_vm._s(_vm.store.name))]),_c(VCardText,[_c(VRow,[_c('div',{staticClass:"my-4 ml-3 text-subtitle-1"},[_vm._v(_vm._s(_vm.store.location))])]),_c(VRow,[_c('div',{staticClass:"ml-3 text-subtitle-1"},[_vm._v(" Phone Number --- "+_vm._s(_vm.store.number)+" ")])]),_c(VRow,[_c('div',{staticClass:"ml-3 text-subtitle-1"},[_vm._v(" Website --- "+_vm._s(_vm.store.website)+" ")])]),(_vm.store.comments)?_c(VRow,[_c('div',{staticClass:"ml-3 text-subtitle-1"},[_vm._v(" Editors' Comments --- "+_vm._s(_vm.store.comments)+" ")])]):_vm._e(),(_vm.store.price != 'NA')?_c('div',{staticClass:"my-4 text-subtitle-1"},[_vm._l((Number(_vm.store.price)),function(i){return (Number(_vm.store.price > 0))?_c('span',[_vm._v("$")]):_vm._e()}),(_vm.store.price)?_c('span',[_vm._v(" • ")]):_vm._e(),_vm._l((_vm.store.cuisine),function(c,index){return _c(VChip,{key:index,staticClass:"mx-2"},[_vm._v(_vm._s(c))])})],2):_c('div',{staticClass:"my-4 text-subtitle-1"},[_vm._v(_vm._s(_vm.store.cuisine))])],1),_c(VDivider,{staticClass:"mx-4"}),_c(VCardTitle,[_vm._v("Store Hours")]),_c(VCardText,[(_vm.store.hours)?_c(VChipGroup,{attrs:{"active-class":"deep-purple accent-4 white--text","column":""}},_vm._l((_vm.store.hours),function(hour){return _c(VChip,{key:hour},[_vm._v(_vm._s(hour))])}),1):_vm._e(),_c(VDivider,{staticClass:"mx-4"})],1),_c('happy-hour-details-vue',{staticClass:"mb-5",attrs:{"happy-hour":this.happyHour}}),_c(VDivider),_c('daily-special-details',{attrs:{"daily-special":this.dailySpecial}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }