<template>
  <v-app id="app">
    <v-app-bar app color="#e8e8e8" flat class="px-8">
      <v-btn icon large @click="$router.push('/')">
        <v-img
          class="px-1"
          contain
          src="https://spring-boot-repo-tpsi.s3.amazonaws.com/logo.svg"
          transition="scale-transition"
          width="45"
          @click="$router.push('/')"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text @click="scroll('home')" class="btn">Home</v-btn>
      <v-btn text @click="scroll('blog')" class="btn">Blog</v-btn> -->
      <v-btn text @click="$router.push('/map')" class="btn">Map</v-btn>
      <v-btn text @click="$router.push('/login')" class="btn"
        >Business Login</v-btn
      >
      <!-- <v-btn text @click="$router.push('/admin')" class="btn">Admin</v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    document.title = "TPSI";
  },
  methods: {
    scroll(refName) {
      if (this.$route.name != "home") {
        this.$router.push("/");
      }
      const element = document.getElementById(refName);
      element.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  font-family: "Oswald Variable", sans-serif;
  /* font-family: "Outfit Variable", sans-serif; */
}
</style>
