<template>
  <v-container>
    <v-row class="manual_upload">
      <v-col
        v-for="(n, index) in this.imageData.photos"
        :key="index"
        class="d-flex child-flex"
        cols="2"
      >
        <v-row>
          {{ index }}
        </v-row>
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/${store_id}_${n.id}`"
          lazy-src="https://spring-boot-repo-tpsi.s3.amazonaws.com/tpsi_logo.png"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <v-row>
            <v-col cols="2">
              <v-btn fab small color="error" @click="deleteImage(index)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn fab small color="info" @click="setImageAsMain(index)"
                ><v-icon>mdi-star</v-icon></v-btn
              >
            </v-col>

            <v-col cols="2" v-if="index > 0">
              <v-btn fab small color="info" @click="moveImageToLeft(index)"
                ><svg-icon type="mdi" :path="path"></svg-icon
              ></v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn fab small color="info" @click="moveImageToRight(index)"
                ><svg-icon type="mdi" :path="path_right"></svg-icon
              ></v-btn>
            </v-col>
          </v-row>

          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <!-- <v-row class="google_result">
      <v-col
        v-for="(n, index) in this.imageData.photos"
        :key="index"
        class="d-flex child-flex"
        cols="2"
      >
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/${store_id}_${n.id}`"
          lazy-src="https://spring-boot-repo-tpsi.s3.amazonaws.com/tpsi_logo.png"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <v-row>
            <v-col cols="9">
              <v-btn fab small color="error" @click="deleteImage(index)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
            <v-col>
              <v-btn fab small color="info" @click="setImageAsMain(index)"
                ><v-icon>mdi-star</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="3">
        <v-file-input
          color="deep-purple accent-4"
          counter
          label="Add New Image"
          multiple
          placeholder="Select your files"
          prepend-icon="mdi-paperclip"
          outlined
          @change="onImageUpload"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="3">
        <v-btn x-large block @click="startUpload()" color="success"
          >Submit</v-btn
        >
      </v-col>
      <v-col col="3">
        <v-btn x-large block color="info" @click="GetStoreDataFromGoogle()"
          >GET IMAGE FROM GOOGLE</v-btn
        >
      </v-col>
      <v-col>
        <v-btn
          x-large
          block
          color="dark grey"
          class="text--white"
          @click="saveImageOrder()"
          >SAVE IMAGE ORDER</v-btn
        ></v-col
      >
    </v-row>
    <v-row> </v-row>

    <result-dialog
      v-if="showResultDialog"
      :visible="showResultDialog"
      :result="updateResult"
      :status="updateStatus"
      @CloseDialog="CloseSuccessDialog()"
    />
  </v-container>
</template>

<script>
import ResultDialog from "./ResultDialog.vue";
import service from "@/services/service";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiArrowLeft } from "@mdi/js";
import { mdiArrowRight } from "@mdi/js";
export default {
  components: {
    ResultDialog,
    SvgIcon,
  },
  data() {
    return {
      path: mdiArrowLeft,
      path_right: mdiArrowRight,
      showResultDialog: false,
      updateResult: "",
      updateStatus: "",
      files: null,
      formData: null,
      imageData: {
        store_id: "",
        photos: [],
      },
      googleImage: {},
    };
  },
  props: {
    store_id: String,
    place_id: String,
  },
  async mounted() {
    const response = await service.getStoreImages(this.store_id);
    if (response.data) {
      console.log("there is image data");
      this.imageData = response.data;
      console.log(this.imageData);
    } else {
      console.log("there is no data");
    }
  },
  methods: {
    moveImageToLeft(index) {
      if (index > 0) {
        const tempPhoto = this.imageData.photos[index];
        this.imageData.photos.splice(index, 1);
        this.imageData.photos.splice(index - 1, 0, tempPhoto);
      }
    },
    moveImageToRight(index) {
      if (index < this.imageData.photos.length - 1) {
        const tempPhoto = this.imageData.photos[index];
        this.imageData.photos.splice(index, 1);
        this.imageData.photos.splice(index + 1, 0, tempPhoto);
      }
    },
    async saveImageOrder() {
      console.log("saving image order");
      var imageDataToSent = this.imageData.photos;
      this.formData = new FormData();
      console.log(this.imageData.photos);
      console.log(imageDataToSent);
      this.formData.append("store_id", this.store_id);
      this.formData.append("data", JSON.stringify(imageDataToSent));
      const response = await service.reorderStoreImages(this.formData);
      console.log(response.data);
    },
    async GetStoreDataFromGoogle() {
      console.log(this.place_id);
      const response = await service.getStoreDetailFromGoogleUsingId(
        this.place_id
      );
      if (response.status === 200) {
        console.log(response.data);
        this.googleStoreInfo = response.data.result;
        console.log(this.googleStoreInfo);
        if (response.data.result) {
          const storeInfoGoogle = response.data.result;
          const storeLocationRealTime = response.data.result.geometry.location;
          this.store.ratingCount = storeInfoGoogle.user_ratings_total;
          this.store.location = storeInfoGoogle.formatted_address;
          this.store.name = storeInfoGoogle.name;
          this.store.number = storeInfoGoogle.formatted_phone_number;
          this.store.website = storeInfoGoogle.website;
          this.store.price = storeInfoGoogle.price_level;
          this.store.rating = storeInfoGoogle.rating;
          this.store.latitude = storeLocationRealTime.lat;
          this.store.longitude = storeLocationRealTime.lng;
          this.store.hours = storeInfoGoogle.current_opening_hours.weekday_text;
          this.store.cuisine = storeInfoGoogle.types;
          this.store.place_id = storeInfoGoogle.place_id;
          storeInfoGoogle.editorial_summary
            ? (this.store.comments = storeInfoGoogle.editorial_summary.overview)
            : "";
          console.log(storeInfoGoogle.photos[0]);
        } else {
          //When there is no data is returned.
          console.log("Nothing is returned.");
        }
      } else {
        console.log("error");
      }
    },
    getImageFromGoogle() {},
    async setImageAsMain(n) {
      console.log("set image as main");
    },
    async deleteImage(n) {
      var imageDataToSent = this.imageData.photos;
      this.formData = new FormData();
      var imageToDelete = this.imageData.photos[n].id;
      imageToDelete = `${this.store_id}_${this.imageData.photos[n].id}`;
      console.log(this.imageData.photos);
      imageDataToSent.splice(n, 1); // TO delete the information from JSON
      console.log(imageDataToSent);
      this.formData.append("imageName", imageToDelete);
      this.formData.append("store_id", this.store_id);
      this.formData.append("data", JSON.stringify(imageDataToSent));
      const response = await service.deleteStoreImage(this.formData);
      console.log(response.data);
    },
    onImageUpload(e) {
      this.formData = new FormData();
      console.log(this.imageData.photos.length);
      console.log(Date.now() + e[0].name);
      //This is to append images to "files" param key
      //when there is no image
      if (this.imageData.photos.length < 1) {
        console.log("there is no image");
        for (let i = 0; i < e.length; i++) {
          console.log(e[i]);
          let time = Date.now();
          this.formData.append("files", e[i]);
          this.imageData.photos.push({
            id: time + e[i].name,
            type: "item",
          });
        }
        const stringPhotos = JSON.stringify(this.imageData.photos);
        this.formData.append("store_id", this.store_id);
        this.formData.append("data", stringPhotos);
        this.formData.append("count", 0);
      } else {
        console.log("there is image");
        const existingImageCount = this.imageData.photos.length;
        //When there is image we need to append image data and images to the list
        for (let i = 0; i < e.length; i++) {
          console.log(e[i]);
          let time = Date.now();
          this.formData.append("files", e[i]);
          this.imageData.photos.push({
            id: time + e[i].name,
            type: "item",
          });
        }
        console.log(existingImageCount);
        const stringPhotos = JSON.stringify(this.imageData.photos);
        this.formData.append("store_id", this.store_id);
        this.formData.append("data", stringPhotos);
        this.formData.append("count", existingImageCount);
      }
    },
    async startUpload() {
      const response = await service.uploadImages(this.formData);
      if (response.status === 200) {
        this.showResultDialog = true;
        this.updateResult = "Image/s upload success...";
        this.updateStatus = response.data;
        this.formData = null;
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
          this.updateStatus = "";
          this.$router.go(0);
        }, 2000);
      }
    },
  },
};
</script>

<style></style>
