<template>
  <v-container fluid>
    <v-container>
      <ApiUsage :data="all_usage" />
    </v-container>
    <v-container fluid>
      <LogTable :data="all_logs" />
    </v-container>
  </v-container>
</template>

<script>
import AdminService from "@/services/AdminService";
import LogTable from "@/components/LogTable.vue";
import ApiUsage from "@/components/ApiUsage.vue";
export default {
  components: {
    LogTable,
    ApiUsage,
  },
  data: () => ({
    all_logs: [],
    all_usage: [],
  }),

  computed: {},

  watch: {},

  async created() {
    await this.getLog();
    await this.getApiUsage();
  },

  methods: {
    async getLog() {
      const response = await AdminService.getAllLogs();
      console.log(response.data);
      this.all_logs = response.data;
    },

    async getApiUsage() {
      const response = await AdminService.getAllUsage();
      console.log(response.data);
      //we only send 4 days of data
      if (response.data.length > 16) {
        const result = [];
        for (let i = 0; i < 15; i++) {
          result.push(response.data[i]);
        }
        this.all_usage = result;
        return;
      } else {
        this.all_usage = response.data;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
