import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.stores,"sort-by":_vm.sort,"item-key":"_id","search":_vm.search,"items-per-page":30,"page":_vm.page},on:{"update:page":_vm.pageChange,"update:sort-by":_vm.sortChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Store List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtn,{staticClass:"white--text ml-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.$router.push('/newstore')}}},[_vm._v("New Store")])],1),_c(VToolbar,[_c('NavBtn')],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.goToEdit(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }