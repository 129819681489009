<template>
  <v-container>
    <result-dialog
      v-if="showResultDialog"
      :visible="showResultDialog"
      :result="updateResult"
      :status="updateStatus"
    />
    <v-card-title>
      <v-col cols="3">
        <v-switch v-model="store.checked" label="Checked"></v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch v-model="store.coop" label="Cooperation Restaurant"></v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch v-model="store.dailySpecial" label="Daily Special"></v-switch>
      </v-col>
      <v-col cols="3">
        <v-switch v-model="store.happyHour" label="Happy Hour"></v-switch>
      </v-col>
    </v-card-title>

    <v-row>
      <v-col cols="8">
        <v-card-subtitle>TPSI ID --- {{ store._id }}</v-card-subtitle>
        <v-card-subtitle v-if="googleStoreInfo.place_id"
          >GOOGLE PLACE ID --- {{ googleStoreInfo.place_id }}</v-card-subtitle
        >
        <v-card-subtitle v-else
          >GOOGLE PLACE ID (EXISTING DATA) --- {{ store.place_id }}</v-card-subtitle
        >
        <v-card-subtitle>RATING --- {{ store.rating }}</v-card-subtitle>
        <v-card-subtitle>REVIEW COUNTS --- {{ store.ratingCount }}</v-card-subtitle>
        <v-card-subtitle>PRICE --- {{ store.price }}</v-card-subtitle>
      </v-col>
      <v-col cols="4">
        <v-btn x-large block color="info">Generate Form url</v-btn>
        <v-card-subtitle>https://www.tpsi.io/testurl</v-card-subtitle>
        <v-card-subtitle>{{ storeFormUrl }}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-card-title> </v-card-title>
    <v-container class="px-8 mb-5">
      <v-row>
        <v-text-field
          v-model="store.googleName"
          label="Google Map Name"
          disabled
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.name" label="TPSI Display Name"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="store.searchName"
          label="Google Search Name"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.number" label="Phone Number"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.website" label="Website"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.menuLink" label="Menu Link"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.email" label="Email"></v-text-field>
      </v-row>
      <v-row>
        <!-- <v-text-field v-model="store.cuisine" label="Cuisine"></v-text-field> -->
        <v-col cols="4">
          <v-select
            label="Cuisine"
            :items="cuisine"
            multiple
            item-text="name"
            v-model="store.cuisine"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-select
            label="Tags"
            :items="tags"
            multiple
            item-value="_id"
            item-text="name"
            @change="onItemChange"
            v-model="store.tags"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-text-field v-model="store.longitude" label="Longitude"></v-text-field>
      </v-row>
      <v-row>
        <v-text-field v-model="store.latitude" label="Latitude"></v-text-field>
      </v-row>
      <v-row>
        <v-textarea
          rows="4"
          v-model="store.comments"
          label="Editor Comments"
        ></v-textarea>
      </v-row>
      <v-row>
        <v-card>
          <v-card-title>Operation Hour</v-card-title>
          <v-card-text>
            <v-chip-group active-class="deep-purple accent-4 white--text">
              <v-chip v-for="hour in store.hours" :key="hour">{{ hour }}</v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-btn color="info" block @click="GetStoreDataFromGoogle()"
          >Get Restaurant Data from Google</v-btn
        >
      </v-row>
      <v-row class="mt-5">
        <v-btn v-if="edit" block class="mt-2" color="success" @click="submitEdit()"
          >SAVE</v-btn
        >
        <v-btn v-else block class="mt-2" color="info" @click="submitNew()">SUBMIT</v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ResultDialog from "./ResultDialog.vue";
import service from "@/services/service";
export default {
  components: {
    ResultDialog,
  },
  props: {
    store: Object,
    edit: Boolean,
  },
  data() {
    return {
      storeFormUrl: "",
      showResultDialog: false,
      updateResult: "",
      updateStatus: false,
      googleStoreInfo: {},
      cuisine: [],
      tags: [],
    };
  },
  created() {
    this.getCuisines();
    this.getTags();
    console.log(this.store);
  },
  methods: {
    submitEditTest() {
      console.log(this.store);
    },
    onItemChange(item) {
      console.log(item);
    },
    async getCuisines() {
      const response = await service.getAllCuisines();
      console.log(response.data);
      this.cuisine = response.data;
      console.log(this.cuisine);
    },
    async getTags() {
      const response = await service.getAllTag();
      console.log(response.data);
      this.tags = response.data;
    },
    async GetStoreDataFromGoogle() {
      const encodedStoreName = encodeURIComponent(this.store.searchName);
      console.log(this.store.searchName);
      console.log(encodedStoreName);
      const response = await service.getStoreDetailFromGoogle(encodedStoreName);
      if (response.status === 200) {
        console.log(response.data);
        this.googleStoreInfo = response.data.result;
        console.log(this.googleStoreInfo);
        if (response.data.result) {
          const storeInfoGoogle = response.data.result;
          const storeLocationRealTime = response.data.result.geometry.location;
          this.store.ratingCount = storeInfoGoogle.user_ratings_total;
          this.store.location = storeInfoGoogle.formatted_address;
          this.store.googleName = storeInfoGoogle.name;
          this.store.number = storeInfoGoogle.formatted_phone_number;
          this.store.website = storeInfoGoogle.website;
          this.store.price = storeInfoGoogle.price_level;
          this.store.rating = storeInfoGoogle.rating;
          this.store.latitude = storeLocationRealTime.lat;
          this.store.longitude = storeLocationRealTime.lng;
          this.store.hours =
            storeInfoGoogle.current_opening_hours.weekday_text != null
              ? storeInfoGoogle.current_opening_hours.weekday_text
              : [];
          this.store.place_id = storeInfoGoogle.place_id;
          console.log(storeInfoGoogle.photos[0]);
        } else {
          //When there is no data is returned.
          console.log("Nothing is returned.");
        }
      } else {
        console.log("error");
      }
    },
    async submitEdit() {
      console.log(this.store);
      if (!this.store.place_id) {
        this.updateStatus = false;
        this.updateResult = "Please Make Sure you get data from Google and then Save";
        this.showResultDialog = true;
        setTimeout(() => {
          this.showResultDialog = false;
          // this.$router.location(0);
          this.updateStatus = "";
          return;
        }, 4000);
      }
      // this.store.place_id = this.googleStoreInfo.place_id;
      if (this.googleStoreInfo.current_opening_hours && this.store.hours.length != 7) {
        this.store.hours = this.googleStoreInfo.current_opening_hours.weekday_text;
      }
      for (let i = this.store.cuisine.length - 1; i >= 0; i--) {
        if (
          this.store.cuisine[i] == "establishment" ||
          this.store.cuisine[i] == "point_of_interest" ||
          this.store.cuisine[i] == "food" ||
          this.store.cuisine[i] == "restaurant" ||
          this.store.cuisine[i] == "bar"
        ) {
          console.log("found" + this.store.cuisine[i]);
          this.store.cuisine.splice(i, 1);
          console.log(this.store.cuisine);
        }
      }
      const string_store = JSON.stringify(this.store);
      console.log(string_store);
      const response = await service.updateStoreInfo(string_store);
      console.log(response.data);
      this.updateStatus = response.data;
      console.log(this.updateStatus);
      if (response.data === true) {
        this.updateResult = "Update Is Success...";
        this.updateStatus = true;
        this.showResultDialog = true;
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateStatus = false;
          this.$router.go(0);
        }, 2000);
      } else {
        //When update failed
        this.updateResult = "Unsuccessful Update, Please Try Again...";
        this.showResultDialog = true;
        setTimeout(() => {
          this.showResultDialog = false;
          this.$router.go(0);
        }, 2000);
      }
    },
    async submitNew() {
      const response = await service.insertNewStore({
        name: this.store.name,
        location: this.store.location,
        number: this.store.number,
        website: this.store.website,
        email: this.store.email,
        cuisine: this.store.cuisine ? this.store.cuisine : [],
        hours: this.store.hours ? this.store.hours : [],
        rating: this.store.rating,
        longitude: this.store.longitude,
        latitude: this.store.latitude,
        price: this.store.price,
        place_id: this.googleStoreInfo.place_id,
        googleName: this.store.googleName,
        searchName: this.store.searchName,
      });
      if (response.data === true) {
        this.updateStatus = true;
        this.updateResult = "Creation Is Success...";
        this.showResultDialog = true;
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateStatus = false;
          this.$router.push("/admin");
        }, 2000);
      } else {
        //When update failed
        this.updateResult = "Unsuccessful Creation, Please Try Again...";
        this.showResultDialog = true;
        setTimeout(() => {
          this.showResultDialog = false;
          this.$router.go(0);
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
