<template>
  <v-dialog v-model="show" width="300">
    <v-card width="auto">
      <v-img
        v-if="!status"
        height="250"
        src="https://spring-boot-repo-tpsi.s3.amazonaws.com/Denied_logo.jpg"
      ></v-img>
      <v-img
        v-else
        contain
        height="250"
        src="https://spring-boot-repo-tpsi.s3.amazonaws.com/Green_check.jpg"
      ></v-img>
      {{ result }}
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  // props: ["visible", "store"],
  props: {
    visible: Boolean,
    result: String,
    status: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("CloseDialog");
        }
      },
    },
  },
  mounted() {},
  data: () => ({}),
  methods: {
    closeDialog() {
      this.$emit("CloseDialog");
    },
  },
};
</script>

<style scoped></style>
