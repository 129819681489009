<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(n, index) in this.imageData.photos"
        :key="index"
        class="d-flex child-flex"
        lg="2"
        sm="6"
        md="4"
      >
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/${store_id}_${n.id}`"
          lazy-src="https://spring-boot-repo-tpsi.s3.amazonaws.com/tpsi_logo.png"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "@/services/service";
export default {
  props: {
    store_id: String,
  },
  async mounted() {
    const response = await service.getStoreImages(this.store_id);
    this.imageData = response.data;
    console.log(this.imageData);
  },
  data() {
    return {
      imageData: {},
    };
  },
};
</script>

<style lang="scss" scoped></style>
