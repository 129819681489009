<template>
  <v-container id="home" fluid class="px-8">
    <v-row align="center">
      <v-col md="8" lg="6">
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/landing1.jpg`"
          :lazy-src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          width="350"
          class="landing1"
        >
        </v-img>
      </v-col>
      <v-col class="landing1text" md="4" lg="6" align="center">
        <div class="">
          <h1 class="orange--text text-wrap">
            Explore NYC's Premier Happy Hour Scene
          </h1>
          <p class="grey--text text-wrap">
            Unlock 500+ hand-picked bars and restaurants offering unbeatable
            drink deals - Anytime, Anywhere
          </p>
        </div>
        <v-row align="center">
          <v-col>
            <button class="getBtn"><span>Get TPSI</span></button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="landing1text" md="4" lg="6" align="center">
        <div>
          <h1 class="orange--text text-wrap">
            Tailor-Made Happy Hours: Your Tastes, Your Deals
          </h1>
          <p class="grey--text">
            Find the perfect drink deals for you based on discounts, times,
            items, prices, and cuisine preferences
          </p>
        </div>
        <v-row align="center">
          <v-col>
            <button class="getBtn"><span>Get TPSI</span></button>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="8" lg="6">
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/landing2.jpg`"
          :lazy-src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          width="350"
          class="landing2"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col md="8" lg="6">
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/landing3.jpg`"
          :lazy-src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          width="350"
          class="landing1"
        >
        </v-img>
      </v-col>
      <v-col class="landing1text" md="4" lg="6" align="center">
        <h1 class="orange--text">Sip Smart: Save Big on NYC's Finest Drinks</h1>
        <p class="grey--text">
          Discover early bird to late night happy hours and enjoy average
          savings of $30 on every outing
        </p>
        <v-row align="center">
          <v-col>
            <button class="getBtn"><span>Get TPSI</span></button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="landing1text" md="4" lg="6" align="center">
        <h1 class="orange--text">Every Happy Hour Detail, Just a Tap Away</h1>
        <p class="grey--text">
          Stay informed and savvy - evaluate happy hour deals with all the info
          you need right at your fingertips
        </p>
        <v-row align="center">
          <v-col>
            <button class="getBtn"><span>Get TPSI</span></button>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="8" lg="6">
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/landing4.jpg`"
          :lazy-src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          width="350"
          class="landing2"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.landing1 {
  display: block;
  margin-left: auto;
  margin-right: 220px;
  width: 50%;
}

.landing1text {
  display: block;
  line-height: 2.5em;
  text-overflow: ellipsis;
  width: 50%;
}

.getBtn {
  border-radius: 4px;
  background-color: #faaf3c;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding: 5px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.getBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.getBtn span:after {
  content: "\270A";
  /* content: url("../assets/logo.svg"); */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.getBtn:hover span {
  padding-right: 20px;
}

.getBtn:hover span:after {
  opacity: 1;
  right: 0;
}

.getBtn:hover {
  border-radius: 0.5rem;
  transition: 0.3s;
}
</style>
