import axios from "axios";

export default () => {
  return axios.create({
    //Local https API
    // baseURL: 'https://localhost:443/api/v1',
    // baseURL: "http://localhost:8080/api/v1",
    //EC2 API
    // baseURL: "https://ec2-34-203-231-63.compute-1.amazonaws.com/api/v1",
    //load balancer API
    // baseURL: "http://tpsi-dev-lb-1064120934.us-east-1.elb.amazonaws.com/api/v1",
    //prod api url
    baseURL: "https://data.tpsi.io/api/v1",
  });
};
