<template>
    <v-container fluid>
      <NewCouponDialog v-model="showDialog"/>
      <v-row class="mb-5">
        <v-col cols="12">
          <BackBtn />
        </v-col>
        <v-col cols="12">
          <v-btn block color="green" dark @click="newStore()">NEW</v-btn>
        </v-col>
      </v-row>
      <v-row>
       
      </v-row>
      <v-row>
        <v-col cols="6" v-for="i in coupons.length" :key="i">
          <CouponCard :coupon-info="coupons[i-1]" @saveCoupon="updateCoupon" @expireCoupon="expireCoupon"/>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import service from "../services/service";
  import BackBtn from "@/components/BackToList.vue"
  import CouponCard from "@/components/CouponCard.vue";
  import NewCouponDialog from "@/components/NewCouponDialog.vue";
  export default {
    components: {
        BackBtn,
        CouponCard,
        NewCouponDialog
    },
    data: () => ({
      showDialog: false,
      error: false,
      formData: null,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Tag",
          align: "start",
          value: "name",
        },
  
        { text: "Actions", value: "actions", sortable: false },
        {
          text: "ID",
          align: "start",
          value: "_id",
        },
      ],
      coupons: {},
    }),
  
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "New Tag" : "Edit Tag";
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
  
    created() {
      this.initialize();
    },
  
    methods: {
      async updateCoupon(coupon) {
        console.log(coupon)
        const response = await service.updateCoupon(coupon)
        console.log(response.data)
        if(response.data == true) {
          this.$router.go(0)
        }
      },
      async expireCoupon(coupon) {
        coupon.expirationDate = "2020-01-01";
        const response = await service.updateCoupon(coupon);
        console.log(response.data)
        if(response.data == true) {
          this.$router.go(0)
        }
      },
      async initialize() {
        const response = await service.getAllCoupon();
        this.coupons = response.data
        console.log(this.coupons)
      },
      newStore() {
        this.showDialog = true;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  