<template>
  <v-container
    fluid
    class="container"
    :style="{ width: '100%', height: `100%` }"
  >
    <InputStageOne
      v-if="stage == 1"
      :currentStore="selectedStore"
      :stage="currentStage"
      :stores="filteredStores"
      @searchStore="searchStore"
      @userSelectStore="storeSelection"
    />
    <InputStageTwo v-if="stage == 2" :currentStore="selectedStore" />
    <div class="buttons">
      <v-row>
        <v-col>
          <v-btn color="blue" block class="btn" @click="stageBack(stage)"
            >BACK</v-btn
          >
        </v-col>
        <v-col>
          <v-btn color="#faaf3c" block class="btn">Skip</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="green" block class="btn" @click="stageProceed(stage)"
            >Next</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import InputStageOne from "@/components/InputStageOne.vue";
import InputStageTwo from "@/components/InputStageTwo.vue";
import service from "@/services/service";
export default {
  name: "businessView",
  components: { InputStageOne, InputStageTwo },
  methods: {},
  data: () => ({
    allStores: {},
    // filteredStores: {},
    stage: 1,
    searchQuery: "",
    selectedStore: {},
  }),
  async mounted() {
    const response = await service.indexAllData();
    this.allStores = response.data;
    console.log(this.allStores);
  },
  computed: {
    currentStage() {
      return this.stage;
    },
    filteredStores() {
      const query = this.searchQuery.toLowerCase();
      if (query === "") {
        return {};
      }
      return this.allStores.filter((store) =>
        store.name.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    searchStore(search) {
      this.searchQuery = search;
    },
    storeSelection(store) {
      console.log("Main: User selected: " + store.toString());
      this.selectedStore = store;
    },
    stageBack(stage) {
      if (stage > 1) {
        this.stage--;
      }
    },
    stageProceed(stage) {
      console.log("1");
      if (stage == 1) {
        if (this.selectedStore.name == null) {
          console.log("Please select store");
          return;
        }
      }
      stage++;
      this.stage = stage;
      console.log(this.stage);
    },
  },
};
</script>

//
<style lang="scss" scoped>
// .buttons {
//   //   position: absolute;
//   bottom: 100x;
// }

// .btn {
//   width: 50%;
// }

.container {
  border: 3px solid red;
}
</style>
