import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,[_c(VRow,_vm._l((_vm.data),function(usage,index){return _c(VCol,{key:index,attrs:{"cols":"4"}},[_c(VCard,[_c(VCardText,[_c('div',[_vm._v(_vm._s(usage.type))]),_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(_vm._s(String(usage.size).slice(0, 7))+"MB")]),_c('p',[_vm._v(_vm._s(usage.date.slice(0, 10)))]),_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(usage.count)+" times")])])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }