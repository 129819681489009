<template>
  <v-container fluid> THIS IS REVIEW PAGE </v-container>
</template>

<script>
import service from "@/services/service";
export default {
  components: {},
  data: () => ({}),

  computed: {},

  watch: {},

  async created() {
    const response = await service.index();
    console.log(response.data);
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
