<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1>Notification</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat color="transparent">
          <v-subheader>Last Login Period</v-subheader>
          <v-card-text>
            <v-row>
              <v-col class="px-4">
                <v-range-slider
                  v-model="range"
                  :max="max"
                  :min="min"
                  hide-details
                  class="align-center"
                >
                  <template v-slot:prepend>
                    <v-text-field
                      :value="range[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 0, $event)"
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field
                      :value="range[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 1, $event)"
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block color="green" @click="applyFilter()">Apply</v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="resetFilter()">Reset</v-btn>
              </v-col>
              <v-col>
                <v-btn block color="blue" @click="showDeviceTokenOnly()"
                  >Show User only with Device Token</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <search-store
                  :stores="filteredStores"
                  :selected_store="selected_store"
                  @searchStore="SearchStore"
                  @selectStore="selectStore"
                ></search-store>
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-text>
            <v-textarea
              dense
              v-model="message"
              label="Message"
              outlined
              rows="3"
              clearable
            ></v-textarea>
            <v-text-field
              outlined
              dense
              label="Title"
              placeholder="TPSI Restaurant"
              v-model="title"
              clearable
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" @click="sendNotification()">Send</v-btn>
          </v-card-actions>
        </v-card>
        <v-alert dense elevation="5" :type="alert_type" v-if="show_alert"></v-alert>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ filtered_users.length }}</th>
            <th class="text-left">Username</th>
            <th class="text-left">First Name</th>
            <th class="text-left">Last Name</th>
            <th class="text-left">Last Login</th>
            <th class="text-left">Last Push</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in filtered_users"
            :key="user._id"
            :style="{ background: user.deviceToken == null ? '#ccc' : 'white' }"
          >
            <td>{{ index }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.lastname }}</td>
            <td>{{ user.lastLogin }}</td>
            <!-- <td>{{ user.lastPush }}</td> -->
            <td>{{ user.deviceToken }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import AdminService from "@/services/AdminService";
import SearchStore from "@/components/SearchStore.vue";
import Service from "@/services/service";
import FormData from "form-data";
export default {
  components: { SearchStore },
  data() {
    return {
      title: "",
      show_alert: false,
      alert_type: "success",
      alert: "",
      users: [],
      stores: [],
      selected_users: [],
      filtered_users: [],
      selected_store: {},
      min: 0,
      max: 100,
      message: "",
      range: [0, 100],
      searchQuery: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    filteredStores() {
      const query = this.searchQuery.toLowerCase();
      if (query === "") {
        return {};
      }
      return this.stores.filter((store) => store.name.toLowerCase().includes(query));
    },
  },
  methods: {
    selectStore(store) {
      this.selected_store = store;
      console.log(this.selected_store);
    },
    SearchStore(search) {
      this.searchQuery = search;
      console.log(this.filteredStores);
    },
    showDeviceTokenOnly() {
      this.filtered_users = this.filtered_users.filter((user) => {
        if (user.deviceToken) {
          return true;
        }
        return false;
      });
    },
    async fetchData() {
      try {
        const response = await AdminService.getAllUsers();
        // console.log(response.data);
        this.users = response.data;
        this.users.forEach((user) => {
          user.selected = false;
        });
        this.filtered_users = this.users;
      } catch (error) {
        console.error(error);
      }
      try {
        const response = await Service.getCheckedStores();
        console.log(response.data);
        this.stores = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    resetFilter() {
      this.range = [0, 100];
      this.filtered_users = this.users;
    },
    applyFilter() {
      this.filtered_users = this.users.filter((user) => {
        var user_login_date = new Date(user.lastLogin);
        var current_date = new Date();
        var day = 60 * 60 * 24 * 1000;
        // if (user_login_date && user.deviceToken) {
        if (user_login_date) {
          if (
            user_login_date.getTime() + day * this.range[0] <= current_date.getTime() &&
            user_login_date.getTime() + day * this.range[1] >= current_date.getTime()
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
      console.log(this.filtered_users);
    },
    async sendNotification() {
      if (this.message == "" || this.selected_store == {}) {
        this.show_alert = true;
        this.alert = "Please fill in all fields";
        this.alert_type = "error";
        return;
      }
      console.log(this.filtered_users);
      console.log(this.message);
      console.log(this.selected_store._id);
      let data = new FormData();
      let user_device_token = [];
      this.filtered_users.forEach((user) => {
        if (user.deviceToken) {
          user_device_token.push(user.deviceToken);
        }
      });
      data.append("body", this.message);
      data.append("store_id", this.selected_store._id);
      data.append("users", user_device_token);
      data.append("title", this.title);

      const response = await Service.postNotification(data);
      console.log(response.data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
