<template>
    <v-btn block color="info" @click="$router.push('/admin')"
    >BACK TO LIST</v-btn
    >
  </template>
  
  <script>
  export default {
    data: () => ({
    }),
  
    computed: {
    },
  
    methods: {
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  