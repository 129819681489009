<template>
    <v-container fluid>
      <v-row class="mb-5">
        <v-col>
          <BackBtn />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="tags"
        sort-by="calories"
        class="elevation-5"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Tags</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  New Item
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Tag name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-alert color="error" v-show="error"
                      >Unable to add new tag</v-alert
                    >
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Refresh Data </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  import service from "../services/service";
  import BackBtn from "@/components/BackToList.vue"
  export default {
    components: {
        BackBtn
    },
    data: () => ({
      error: false,
      formData: null,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Tag",
          align: "start",
          value: "name",
        },
  
        { text: "Actions", value: "actions", sortable: false },
        {
          text: "ID",
          align: "start",
          value: "_id",
        },
      ],
      tags: [],
      editedIndex: -1,
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
      },
    }),
  
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "New Tag" : "Edit Tag";
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
  
    created() {
      this.initialize();
    },
  
    methods: {
      async initialize() {
        const response = await service.getAllTag();
        console.log(response.data);
        this.tags = response.data;
      },
  
      editItem(item) {
        this.editedIndex = this.tags.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  
      deleteItem(item) {
        this.editedIndex = this.tags.indexOf(item);
        this.editedItem = Object.assign({}, item);
        console.log(this.editedItem);
        this.dialogDelete = true;
      },
  
      async deleteItemConfirm() {
        const response = await service.deleteTag(this.editedItem);
        this.closeDelete();
        this.initialize();
      },
  
      close() {
        this.dialog = false;
        this.error = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      async save() {
        var response;
        if (this.editedIndex > -1) {
          Object.assign(this.tags[this.editedIndex], this.editedItem);
          console.log(this.editedItem);
          response = await service.updateTag(this.editedItem);
          console.log("this is edit");
        } else {
          //this is to add new tag
          this.tags.push(this.editedItem);
          console.log(this.editedItem.name);
          this.formData = new FormData();
          this.formData.append("name", this.editedItem.name);
          response = await service.postNewTag(this.formData);
          console.log("this is new");
        }
        console.log(response.data);
        if (response.data === false) {
          this.error = true;
          setTimeout(() => {
            this.close();
            this.$router.go(0);
          }, 1500);
        } else {
          this.close();
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  