<template>
  <div class="dropdown">
    <v-row>
      <v-col align="center">
        <v-text-field
          outlined
          dense
          label="Search For Business Name"
          placeholder="TPSI Restaurant"
          v-model="search"
          @keyup="userSearch(search)"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="options">
      <v-row>
        <v-col v-if="showSearch">
          <ul>
            <li v-for="(store, i) in stores" :key="i" @click="selectStore(store)">
              <v-row
                ><div class="name">{{ store.name }}</div></v-row
              >
              <v-row
                ><div class="address">
                  {{ store.location }}
                </div></v-row
              >
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["stores", "selected_store"],
  mounted() {
    console.log(this.stores);
  },
  data() {
    return {
      search: "",
      selectedStore: {},
      showSearch: true,
    };
  },
  methods: {
    userSearch(searchWord) {
      this.showSearch = true;
      console.log(searchWord);
      this.$emit("searchStore", searchWord);
    },
    selectStore(store) {
      this.$emit("selectStore", store);
      this.showSearch = false;
      this.search = store.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  margin-top: 1px;
}

ul {
  text-align: left;
  padding-left: 0px;
  overflow-y: scroll;
  max-height: 150px;
  //   max-width: fit-content;
}
li {
  //   list-style: none;
  text-align: left;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-left: 5px;
  background-color: #f1f1f1;
  &:hover {
    background-color: #70878a;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
}

.name {
  font-size: larger;
}
.address {
  font-size: small;
  color: #575757;
}
</style>
