<template>
  <v-container>
    <v-btn v-if="edit" class="mb-12" block @click="$router.push('/admin')" color="success"
      >Back To List</v-btn
    >
    <v-btn v-else class="mb-12" block @click="$router.push('/admin')" color="blue"
      >Back To List</v-btn
    >

    <v-card class="mx-auto my-12">
      <upload-image
        :store_id="this.$route.params.storeId"
        :place_id="this.store.place_id"
      />
      <edit-store-content :store="store" :edit="edit" />
      <daily-special-content
        v-if="store"
        :id="this.$route.params.storeId"
        @receiveDailySpecialData="getDailySpecial"
      />
      <happy-hour-content v-if="store" :store_id="this.$route.params.storeId" />
    </v-card>
    <v-sheet class="mx-auto">
      <v-form validate-on="submit lazy" @submit.prevent="submit">
        <v-divider></v-divider>
      </v-form>
    </v-sheet>
  </v-container>
</template>
<script>
import EditStoreContent from "../components/EditStoreContent.vue";
import ResultDialog from "../components/ResultDialog.vue";
import DailySpecialContent from "@/components/DailySpecialContent.vue";
import HappyHourContent from "@/components/HappyHourContent.vue";
import UploadImage from "@/components/UploadImage.vue";
import service from "@/services/service";
export default {
  components: {
    ResultDialog,
    DailySpecialContent,
    HappyHourContent,
    UploadImage,
    EditStoreContent,
  },
  data() {
    return {
      store: {},
      searchValue: "",
      showResultDialog: false,
      updateResult: "",
      updateStatus: "",
      edit: false,
      googleStoreInfo: {},
      selected: [],
    };
  },
  async mounted() {
    const storeId = this.$route.params.storeId;
    this.edit = storeId;
    //if there is storeId, this is edit page, otherwise, we are using this page as create new store
    if (storeId) {
      this.edit = true;
      const response = await service.show(storeId);
      this.store = response.data;
      console.log(this.store);
      if (!this.store.coop) {
        console.log("it null");
        this.store.coop = false;
      } else {
        console.log("its not null");
      }
      if (this.store.hours && this.store.hours.length > 10) {
        this.store.hours = [];
      }
    }
  },
  methods: {
    getDailySpecial(value) {
      console.log(value);
    },
  },
};
</script>
