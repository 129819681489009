import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"lg":"8","sm":"2"}},[_c(VImg,{attrs:{"src":`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`,"lazy-src":`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`,"contained":""}})],1),_c(VCol,{staticClass:"loginForm"},[_c('div',[_c('div',{staticClass:"loginTitle"},[_c(VRow,[_c('div',{staticClass:"text-h5"},[_vm._v("Welcome to TPSI Admin Page! 👋🏻")])]),_c(VRow,[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v(" Please sign-in to your account and start the registration ")])])],1),_c(VTextField,{staticClass:"inputField",attrs:{"label":"Username Or Email","placeholder":"johndoe@tpsi.io","outlined":""}}),_c(VTextField,{staticClass:"inputField",attrs:{"label":"Password","placeholder":"***********","type":"password","outlined":""}}),_c(VRow,{attrs:{"align":"end"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"label":"Remember Me","color":"red darken-3","value":"true","hide-details":""},model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}})],1),_c(VCol,[_c('div',{staticClass:"text-body-2 grey--text text-right"},[_c('a',{attrs:{"href":""}},[_vm._v("Forget Password?")])])])],1),_c(VRow,[_c(VCol,[_c('button',{staticClass:"loginBtn",on:{"click":_vm.login}},[_c('span',[_vm._v(" Login ")])])])],1),_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c('div',[_vm._v("New To Our Platform? "),_c('a',{attrs:{"href":""}},[_vm._v("Create an Account")])])])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }