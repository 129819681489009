import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[_c(VAppBar,{staticClass:"px-8",attrs:{"app":"","color":"#e8e8e8","flat":""}},[_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c(VImg,{staticClass:"px-1",attrs:{"contain":"","src":"https://spring-boot-repo-tpsi.s3.amazonaws.com/logo.svg","transition":"scale-transition","width":"45"},on:{"click":function($event){return _vm.$router.push('/')}}})],1),_c(VSpacer),_c(VBtn,{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/map')}}},[_vm._v("Map")]),_c(VBtn,{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Business Login")])],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }