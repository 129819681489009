<template>
  <v-container>
    <v-toolbar color="info" dark flat>
      <v-toolbar-title>Daily Special</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab v-for="(day, index) in dailySpecial.dayInfo" :key="index">
            {{ days[Number(day.day - 1)] }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in dailySpecial.dayInfo" :key="index">
        <v-card flat class="pb-12">
          <v-card-title
            >{{ item.start_time }} - {{ item.end_time }}</v-card-title
          >
          <v-container class="">
            <!-- THIS IS THE TABLE TO BE HIDDEN WHEN USER USE DESKTOP -->
            <!-- DISPLAY FOR MOBILE USER -->
            <v-simple-table dense class="d-sm-none">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">NAME</th>
                    <th class="text-left">OFF PRICE</th>
                    <th class="text-left">REG PRICE</th>
                    <th class="text-left">TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in item.menus"
                    :key="index"
                    class="mx-12"
                  >
                    <td>{{ product.name }}</td>
                    <td>${{ product.discounted_price }}</td>
                    <td>${{ product.regular_price }}</td>
                    <td>{{ product.type }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- THIS IS FOR DESKTOP USER INCLUDING IPAD -->
            <v-simple-table dense class="d-none d-sm-block">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">NAME</th>
                    <th class="text-left">DISCOUNTED PRICE</th>
                    <th class="text-left">REGULAR PRICE</th>
                    <th class="text-left">TYPE</th>
                    <th class="text-left">ALCOHOL%</th>
                    <th class="text-left">TASTE SCORE</th>
                    <th class="text-left">INNOVATION SCORE</th>
                    <th class="text-left">PORTION SCORE</th>
                    <th class="text-left">INGREDIENTS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in item.menus"
                    :key="index"
                    class="mx-12"
                  >
                    <td>{{ product.name }}</td>
                    <td>${{ product.discounted_price }}</td>
                    <td>${{ product.regular_price }}</td>
                    <td>{{ product.type }}</td>
                    <td class="">{{ product.alcohol }}%</td>
                    <td class="">{{ product.taste_score }}</td>
                    <td class="">
                      {{ product.innovation_score }}
                    </td>
                    <td class="">{{ product.ingredients }}</td>
                    <td class="">
                      {{ product.portion_score }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  props: {
    dailySpecial: Object,
  },
  data() {
    return {
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<style lang="scss" scoped></style>
