import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.edit)?_c(VBtn,{staticClass:"mb-12",attrs:{"block":"","color":"success"},on:{"click":function($event){return _vm.$router.push('/admin')}}},[_vm._v("Cancel")]):_c(VBtn,{staticClass:"mb-12",attrs:{"block":"","color":"blue"},on:{"click":function($event){return _vm.$router.push('/admin')}}},[_vm._v("Cancel")]),_c(VCard,{staticClass:"mx-auto my-12"},[_c('upload-image',{attrs:{"store_id":this.$route.params.storeId,"place_id":this.store.place_id}}),_c('edit-store-content',{attrs:{"store":_vm.store,"edit":_vm.edit}}),(_vm.store)?_c('daily-special-content',{attrs:{"id":this.$route.params.storeId},on:{"receiveDailySpecialData":_vm.getDailySpecial}}):_vm._e(),(_vm.store)?_c('happy-hour-content',{attrs:{"store_id":this.$route.params.storeId}}):_vm._e()],1),_c(VSheet,{staticClass:"mx-auto"},[_c(VForm,{attrs:{"validate-on":"submit lazy"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VDivider)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }