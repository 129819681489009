<template>
  <div>
    <!-- <div>{{ data }}</div> -->
    <v-container>
      <v-row>
        <v-col v-for="(usage, index) in data" :key="index" cols="4">
          <v-card>
            <!-- {{ usage }} -->
            <v-card-text>
              <div>{{ usage.type }}</div>
              <p class="text-h4 text--primary">{{ String(usage.size).slice(0, 7) }}MB</p>
              <p>{{ usage.date.slice(0, 10) }}</p>
              <div class="text--primary">{{ usage.count }} times</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped></style>
