import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@fontsource-variable/oswald";
import "@fontsource-variable/outfit";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAfcKylwQfUMgZDC3Mv2-1c8fklETIIa4c",
    libraries: "places",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
