<template>
  <div class="stage1">
    <v-row>
      <v-col align="center">
        <div class="text-h5">
          Tell Customers Which Phone Number They Can Call
        </div>
      </v-col>
    </v-row>
    <div class="dropdown">
      <v-row>
        <v-col align="center">
          <v-text-field
            outlined
            dense
            label="Business Phone Number"
            placeholder="888-888-8888"
            v-model="phoneNumber"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentStore"],
  emits: ["searchStore"],
  data: () => ({
    // windowHeight: window.innerHeight,
    phoneNumber: "",
    selectedStore: {},
  }),
  mounted() {
    this.phoneNumber = this.currentStore.number;
    console.log(this.currentStore);
  },
  methods: {
    selectStore(index) {
      console.log("selected Store is at " + index);
      this.selectedStore = this.stores[index];
      console.log(this.selectedStore);
      this.search = this.selectedStore.name;
      this.$emit("userSelectStore", this.selectedStore);
    },
    userSearch(searchWord) {
      console.log(searchWord);
      this.$emit("searchStore", searchWord);
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  margin-top: 1px;
}

ul {
  text-align: left;
  padding-left: 0px;
  overflow-y: scroll;
  max-height: 250px;
}
li {
  list-style: none;
  text-align: left;
  border-bottom: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 20px;
  //   margin-left: 5px;
  background-color: #f1f1f1;
  &:hover {
    background-color: #70878a;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
}

.name {
  font-size: larger;
}
.address {
  font-size: small;
  color: #575757;
}
</style>
