<template>
  <v-container>
    <v-card color="basil">
      <v-card-title class="text-center justify-center py-6">
        <v-row>
          <v-col offset="2">
            <h1 class="pr-12 font-weight-bold text-h2">Daily Specials</h1>
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="dsContent.bar_patio_only"
              label="Bar/Patio ONLY"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-title>

      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in days" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(day, index) in dsContent.dayInfo" :key="index">
          <v-card color="basil" flat>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    v-model="day.start_time"
                    prepend-icon="mdi-clock-time-four-outline"
                    label="Start Time 24H Format"
                  ></v-text-field
                ></v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="day.end_time"
                    prepend-icon="mdi-clock-time-four-outline"
                    label="End Time 24H Format"
                  ></v-text-field
                ></v-col>
                <v-col>
                  <v-switch v-model="day.apply_today" hide-details inset>
                    <template v-slot:label>
                      Valid {{ days[day.day - 1] }}
                    </template>
                  </v-switch>
                </v-col>
                <v-col class="mt-3">
                  <v-btn block @click="copy(index)">COPY CURRENT CONTENT</v-btn>
                </v-col>
                <v-col class="mt-3">
                  <v-btn block @click="paste(index)">PASTE TO TODAY</v-btn>
                </v-col>
              </v-row>
              <v-container class="content">
                <v-row
                  v-for="(item, index) in day.menus"
                  :key="index"
                  class="content1"
                >
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.name"
                      label="Item Name"
                    ></v-text-field
                  ></v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.discounted_price"
                      label="Discounted Price"
                    ></v-text-field
                  ></v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.regular_price"
                      label="Regular Price"
                    ></v-text-field
                  ></v-col>
                  <v-col sm="2">
                    <v-select
                      v-model="item.type"
                      label="Type"
                      :items="itemTypes"
                    ></v-select
                  ></v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.alcohol"
                      label="Alcohol %"
                    ></v-text-field
                  ></v-col>

                  <v-col sm="2">
                    <v-text-field
                      v-model="item.taste_score"
                      label="Taste Score"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="1" sm="2">
                    <v-text-field
                      v-model="item.innovation_score"
                      label="Innovation Score"
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.ingredients"
                      label="Ingredients"
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.portion_score"
                      label="Portion Score"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2"
                    ><v-btn color="error" @click="removeItem(index, day)"
                      >Remove</v-btn
                    ></v-col
                  >
                </v-row>
              </v-container>
              <v-container>
                <v-alert
                  color="red"
                  dark
                  :value="showAlert"
                  class="overflow-y-auto"
                >
                  {{ warningContent }}
                </v-alert>
                <v-row
                  ><v-btn class="" block color="info" @click="addItem(day)"
                    >ADD ITEM for {{ days[day.day - 1] }}</v-btn
                  ></v-row
                >
                <v-row>
                  <v-btn
                    class="my-5"
                    color="green"
                    @click="saveDailySpecial()"
                    block
                    >SAVE DAILY SPECIAL</v-btn
                  >
                </v-row>
              </v-container>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-container> </v-container>
      <result-dialog
        v-if="showResultDialog"
        :visible="showResultDialog"
        :result="updateResult"
        :status="updateStatus"
        @CloseDialog="CloseSuccessDialog()"
      />
    </v-card>
  </v-container>
</template>
<script>
import ResultDialog from "./ResultDialog.vue";
import service from "../services/service";
export default {
  props: {
    id: String,
  },
  components: {
    ResultDialog,
  },
  async mounted() {
    const response = await service.getStoreDailySpecial(this.id);
    if (response.data) {
      this.dsContent = response.data;
    } else {
      this.dsContent = {
        store_id: this.id,
        valid: false,
        dayInfo: [
          {
            day: 1,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 2,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 3,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 4,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 5,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 6,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
          {
            day: 7,
            apply_today: false,
            start_time: "",
            end_time: "",
            menus: [],
          },
        ],
      };
    }
  },
  computed: {},
  data() {
    return {
      copiedMenu: {},
      itemTypes: ["Food", "Wine", "Beer", "Cocktail"],
      updateResult: "",
      updateStatus: "",
      showResultDialog: false,
      time: null,
      menu1: false,
      menu2: false,
      showAlert: false,
      warningContent: "",
      color: "red",
      tab: null,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      dsContent: {},
    };
  },
  methods: {
    checkStartEnd() {
      for (let i = 0; i < this.dsContent.dayInfo.length; i++) {
        console.log(this.dsContent.dayInfo[i]);
        let pass = true;
        let currentData = this.dsContent.dayInfo[i];
        if (currentData.menus.length > 0) {
          if (currentData.start_time === "" || currentData.end_time === "") {
            pass = false;
          }
        }
        console.log("pass: " + pass);
        if (!pass) return false;
      }
      return true;
    },
    resetCopyContent() {
      this.copiedMenu = {};
    },
    copy(index) {
      console.log(this.dsContent.dayInfo[index]);
      this.copiedMenu = this.dsContent.dayInfo[index];
      console.log("Content is being added to the clip board");
    },
    paste(index) {
      this.dsContent.dayInfo[index].menus = this.copiedMenu.menus;
      this.dsContent.dayInfo[index].start_time = this.copiedMenu.start_time;
      this.dsContent.dayInfo[index].end_time = this.copiedMenu.end_time;
      this.dsContent.dayInfo[index].apply_today = this.copiedMenu.apply_today;
      this.resetCopyContent();
    },
    sendInfo() {
      this.$emit("receiveDailySpecialData", true);
    },
    async saveDailySpecial() {
      const passForSave = this.checkStartEnd();
      console.log(passForSave);
      if (!passForSave) {
        this.showResultDialog = true;
        this.updateStatus = passForSave;
        this.updateResult =
          "Please fill out start and end time for all Daily Special entries!!!";
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
        }, 4000);
        return;
      }
      const response = await service.saveStoreDailySpecial({
        store_id: this.dsContent.store_id,
        valid: this.dsContent.valid,
        dayInfo: this.dsContent.dayInfo,
      });
      console.log(response);
      this.updateStatus = response.data;
      if (response.data) {
        this.showResultDialog = true;
        this.updateResult = "Update Success...";
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
          this.$router.go(0);
        }, 2000);
      } else {
        this.showResultDialog = true;
        this.updateResult = "Update Is Not Success....";
        setTimeout(() => {
          this.showResultDialog = false;
          this.updateResult = "";
        }, 2000);
      }
    },
    removeItem(index, day) {
      console.log(index);
      //array that holds items
      console.log(typeof day.menus);
      day.menus.splice(index, 1);
    },
    sendDSData() {
      this.happyHourExample._id = this._id;
      console.log(this.happyHourExample);
    },
    addItem(day) {
      const currentItemsIndex = day.menus.length;
      const lastItem = day.menus[currentItemsIndex - 1];
      console.log(currentItemsIndex);
      //first check if there is content for daily special
      if (currentItemsIndex != 0) {
        if (lastItem.name != "") {
          day.menus.push({
            name: "",
            discounted_price: "",
            regular_price: "",
            type: "",
            alcohol: 0,
            taste_score: 0,
            innovation_score: 0,
            ingredients: "",
            portion_score: 0,
          });
        } else {
          this.warningContent = `Please don't create empty item`;
          this.showAlert = true;
          setTimeout(() => {
            this.resetAlert();
          }, 2500);
        }
      } else {
        day.menus.push({
          name: "",
          discounted_price: "",
          regular_price: "",
          type: "",
          alcohol: 0,
          taste_score: 0,
          innovation_score: 0,
          ingredients: "",
          portion_score: 0,
        });
      }
    },
    resetAlert() {
      this.showAlert = false;
      this.warningContent = "";
    },
  },
};
</script>
<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>
