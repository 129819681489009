<template>
  <v-card>
    <v-card-title> Store Name: {{ couponCopy.store }} </v-card-title>
    <v-card-subtitle v-if="editable == false">
      <div>Location: {{ couponCopy.location }}</div>
      <div>Expiration Date: {{ couponCopy.expirationDate }}</div>
      <div>Note: {{ couponCopy.note }}</div>
    </v-card-subtitle>
    <v-card-subtitle v-else>
      <div>Location: {{ couponCopy.location }}</div>
      <div>
        <v-text-field
          label="Expiration Date"
          v-model="couponCopy.expirationDate"
        ></v-text-field>
      </div>
      <div><v-text-field label="Note" v-model="couponCopy.note"></v-text-field></div>
    </v-card-subtitle>
    <v-card-text v-if="editable == false">
      <div v-for="i in couponCopy.deals.length" :key="i">
        {{ couponCopy.deals[i - 1] }}
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div v-for="i in couponCopy.deals.length" :key="i">
        <v-row>
          <v-col cols="11">
            <v-text-field label="Deals" v-model="couponCopy.deals[i - 1]"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-col cols="1"
              ><v-btn small class="mt-2" color="error" rounded @click="removeDeal(i)"
                ><v-icon>mdi-delete</v-icon></v-btn
              ></v-col
            >
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col><v-btn block @click="addEntry()">ADD DEAL</v-btn></v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click.stop="editData()" v-if="editable == false">Edit</v-btn>
      <v-btn @click.stop="save()" v-if="editable == true" color="green">SAVE</v-btn>
      <v-btn @click.stop="uneditData()" v-if="editable == true" color="error"
        >Cancel</v-btn
      >
      <v-btn @click.stop="expireCoupon()" v-if="editable == true" color="error"
        >EXPIRE COUPON</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["couponInfo"],
  data: () => ({
    editable: false,
    couponCopy: {},
    Copy: {},
  }),
  created() {
    this.couponCopy = this.couponInfo;
  },
  methods: {
    save() {
      console.log(this.couponCopy);
      this.$emit("saveCoupon", this.couponCopy);
    },
    editData() {
      localStorage.setItem("data", JSON.stringify(this.couponInfo));
      this.editable = true;
    },
    uneditData() {
      this.couponCopy = JSON.parse(localStorage.getItem("data"));
      this.couponInfo = this.couponCopy;
      this.editable = false;
    },
    addEntry() {
      if (this.couponCopy.deals[this.couponCopy.deals.length - 1] == "") {
        console.log("cannot add more");
        return;
      } else {
        this.couponCopy.deals.push("");
      }
    },
    removeDeal(index) {
      this.couponCopy.deals.splice(index - 1, 1);
    },
    expireCoupon() {
      this.$emit("expireCoupon", this.couponCopy);
    },
  },
};
</script>

<style lang="scss" scoped></style>
