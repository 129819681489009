<template>
  <data-table />
</template>

<script>
import DataTable from "../components/StoreListDataTable";

export default {
  name: "Home",

  components: {
    DataTable,
  },
};
</script>
