import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,_vm._l((this.imageData.photos),function(n,index){return _c(VCol,{key:index,staticClass:"d-flex child-flex",attrs:{"lg":"2","sm":"6","md":"4"}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":`https://spring-boot-repo-tpsi.s3.amazonaws.com/${_vm.store_id}_${n.id}`,"lazy-src":"https://spring-boot-repo-tpsi.s3.amazonaws.com/tpsi_logo.png","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }