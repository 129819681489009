<template>
  <v-container fluid>
    <v-row align="center">
      <v-col lg="8" sm="2" class="hidden-sm-and-down">
        <v-img
          :src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          :lazy-src="`https://spring-boot-repo-tpsi.s3.amazonaws.com/happyhourtitle.jpg`"
          contained
        >
        </v-img>
      </v-col>
      <v-col class="loginForm">
        <div>
          <div class="loginTitle">
            <v-row>
              <div class="text-h5">Welcome to TPSI Admin Page! 👋🏻</div>
            </v-row>
            <v-row>
              <div class="text-subtitle-1 grey--text">
                Please sign-in to your account and start the registration
              </div>
            </v-row>
          </div>
          <v-text-field
            class="inputField"
            label="Username Or Email"
            placeholder="johndoe@tpsi.io"
            outlined
          ></v-text-field>
          <v-text-field
            class="inputField"
            label="Password"
            placeholder="***********"
            type="password"
            outlined
          ></v-text-field>
          <v-row align="end">
            <v-col cols="6">
              <v-checkbox
                v-model="rememberMe"
                label="Remember Me"
                color="red darken-3"
                value="true"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col>
              <div class="text-body-2 grey--text text-right">
                <a href="">Forget Password?</a>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <button class="loginBtn" @click="login">
                <span> Login </span>
              </button>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <div>New To Our Platform? <a href="">Create an Account</a></div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "login",
  components: {},
  methods: {
    login() {
      this.$router.push("/business");
    },
  },
  data: () => ({
    rememberMe: false,
  }),
};
</script>
//
<style scoped>
.loginTitle {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.inputField {
  margin-top: auto;
  border-radius: 0.5rem;
}
.loginForm {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.loginBtn {
  display: block;
  width: 100%;
  max-height: fit-content;
  border-radius: 4px;
  background-color: #faaf3c;
  text-align: center;
  padding: 5px;
  transition: all 0.5s;
  cursor: pointer;
}

.loginBtn:hover span {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
