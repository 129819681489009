<template>
  <v-dialog v-model="show" width="auto">
    <v-card width="auto">
      <image-view :store_id="store._id" />
      <v-card-title>{{ store.name }}</v-card-title>

      <v-card-text>
        <v-row>
          <div class="my-4 ml-3 text-subtitle-1">{{ store.location }}</div>
        </v-row>
        <v-row>
          <div class="ml-3 text-subtitle-1">
            Phone Number --- {{ store.number }}
          </div>
        </v-row>
        <v-row>
          <div class="ml-3 text-subtitle-1">
            Website --- {{ store.website }}
          </div>
        </v-row>
        <!-- <v-row>
          <div class="ml-3 text-subtitle-1">TPSI ID ---- {{ store._id }}</div>
        </v-row>
        <v-row>
          <div class="ml-3 text-subtitle-1">
            GOOGLE ID --- {{ store.place_id }}
          </div>
        </v-row> -->
        <v-row v-if="store.comments">
          <div class="ml-3 text-subtitle-1">
            Editors' Comments --- {{ store.comments }}
          </div>
        </v-row>
        <div class="my-4 text-subtitle-1" v-if="store.price != 'NA'">
          <span v-if="Number(store.price > 0)" v-for="i in Number(store.price)"
            >$</span
          >
          <span v-if="store.price"> • </span>
          <v-chip
            v-for="(c, index) in store.cuisine"
            :key="index"
            class="mx-2"
            >{{ c }}</v-chip
          >
        </div>
        <div class="my-4 text-subtitle-1" v-else>{{ store.cuisine }}</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Store Hours</v-card-title>

      <v-card-text>
        <v-chip-group
          active-class="deep-purple accent-4 white--text"
          column
          v-if="store.hours"
        >
          <v-chip v-for="hour in store.hours" :key="hour">{{ hour }}</v-chip>
        </v-chip-group>
        <v-divider class="mx-4"></v-divider>
      </v-card-text>
      <happy-hour-details-vue :happy-hour="this.happyHour" class="mb-5" />
      <v-divider></v-divider>
      <daily-special-details :daily-special="this.dailySpecial" />
    </v-card>
  </v-dialog>
</template>
<script>
import ImageView from "./ImageView.vue";
import HappyHourDetailsVue from "./HappyHourDetails.vue";
import DailySpecialDetails from "./DailySpecialDetails.vue";
import service from "../services/service";
export default {
  props: {
    visible: Boolean,
    store: Object,
  },
  components: {
    ImageView,
    HappyHourDetailsVue,
    DailySpecialDetails,
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("CloseDialog");
        }
      },
    },
  },
  async mounted() {
    console.log(this.windowHeight);
    if (this.windowHeight < 930) {
    }
    if (this.store.dailySpecial) {
      // run for get daily special
      console.log("THERE IS DAILY SPECIAL DATA");
      const dailySpecialResponse = await service.getStoreDailySpecial(
        this.store._id
      );
      if (dailySpecialResponse.data) {
        this.dailySpecial = dailySpecialResponse.data;
      }
    } else {
      console.log("THERE IS NO DAILY SPECIAL DATA");
    }
    if (this.store.happyHour) {
      console.log("THERE IS HAPPY HOUR DATA");
      // run for get happy hour
      const happyHourResponse = await service.getStoreHappyHour(this.store._id);
      if (happyHourResponse.data) {
        this.happyHour = happyHourResponse.data;
      }
    } else {
      console.log("THERE IS NO HAPPY HOUR DATA");
    }
  },
  data: () => ({
    windowHeight: window.innerHeight,
    imageInfo: {},
    days: [],
    selection: "",
    priceRating: 0,
    happyHour: {},
    dailySpecial: {},
  }),
  methods: {
    closeDialog() {
      this.$emit("CloseDialog");
    },
  },
};
</script>

<style scoped></style>
